import React, { useState, useEffect, useRef } from "react";

import {Link} from "@nextui-org/react";
import {Tabs, Tab, Card, CardHeader, CardBody, CardFooter, Image, Button, Navbar, NavbarBrand, NavbarContent, NavbarItem, NavbarMenuToggle, NavbarMenu, NavbarMenuItem, Tooltip, Spinner} from "@nextui-org/react";
import {
    Listbox,
    ListboxItem,
    Dropdown,
    DropdownTrigger,
    DropdownMenu,
    DropdownItem,
} from "@nextui-org/react";

export function GetApp() {
    // useEffect(() => {
    //     getEthPrice()
    // }, []);

    return (
        <>
            <div className="p-6 mx-auto max-w-5xl">
                <Card>
                    <CardHeader>
                        <p>This site can be installed as an application!</p>
                    </CardHeader>
                    <CardBody>
                        <p>Steps:</p>

                        <p>Tap the share button</p>
                        <p>Click add to homescreen</p>
                    </CardBody>
                    <CardFooter>
                        <Link href="/">home</Link>
                    </CardFooter>
                </Card>
            </div>
        </>
    )
}

