import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';

// import { useAlert } from './alert_context';
import { useAlert } from './alert_context';
import { getIsPWA } from '../pwa/check/is_pwa';
import { InstallAppPrompt } from '../pwa/install_app_prompt';

const PWAContext = createContext(null);

export const PWAProvider = ({ children }) => {
    const location = useLocation();

    const { showAlert } = useAlert();
    // showAlert(`Failed to switch to the chain: ${result.error.message}`, 'error', 'bottom');

    const [isPWA, setIsPWA] = useState(getIsPWA());
    const [isMicPermissionGranted, setIsMicPermissionGranted] = useState(false);
    const [micPermissionStatus, setMicPermissionStatus] = useState('unknown');
    const [isGeoLocationPermissionGranted, setIsGeoLocationPermissionGranted] = useState(false);
    const [geoLocationPermissionStatus, setGeoLocationPermissionStatus] = useState('unknown');

    useEffect(() => {
        setIsPWA(getIsPWA());
    }, []);

    useEffect(() => {
        if (isPWA) {
            checkMicrophonePermission();
            checkGeoLocationPermission();
        }
    }, [isPWA]);

    // if (!isPWA) {
    //     return <InstallAppPrompt />;
    // }

    const isAppRoute = location.pathname.startsWith('/app');

    // todo: can also just redirect to index instead of showing the prompt
    if (isAppRoute && !isPWA) {
        return <InstallAppPrompt />;
    }

    const requestMicrophonePermission = () => {
        navigator.mediaDevices.getUserMedia({ audio: true })
        .then((stream) => {
            stream.getTracks().forEach(track => track.stop()); // Close the stream after getting permission
            setIsMicPermissionGranted(true);
            setMicPermissionStatus('granted');
        })
        .catch((error) => {
            console.error('Error accessing microphone:', error);
            setIsMicPermissionGranted(false);
            setMicPermissionStatus('denied');
        });
    };

    const checkMicrophonePermission = () => {
        navigator.permissions.query({ name: 'microphone' })
        .then((result) => {
            if (result.state === 'granted') {
                setIsMicPermissionGranted(true);
                setMicPermissionStatus('granted');
            } else if (result.state === 'prompt') {
                requestMicrophonePermission();
                setMicPermissionStatus('prompt');
            } else {
                setIsMicPermissionGranted(false);
                setMicPermissionStatus('denied');
            }

            result.onchange = () => {
                if (result.state === 'granted') {
                    setIsMicPermissionGranted(true);
                } else {
                    setIsMicPermissionGranted(false);
                }
            };
        })
        .catch((error) => {
            console.error('Error checking microphone permissions:', error);
            setMicPermissionStatus('error');
        });
    };

    const requestGeoLocationPermission = () => {
        navigator.geolocation.getCurrentPosition(
            (position) => {
                setIsGeoLocationPermissionGranted(true);
                setGeoLocationPermissionStatus('granted');
            },
            (error) => {
                console.error('Error getting geolocation:', error);
                setIsGeoLocationPermissionGranted(false);
                setGeoLocationPermissionStatus('denied');
            }
        );
    };

    const checkGeoLocationPermission = () => {
        navigator.permissions.query({ name: 'geolocation' })
        .then((result) => {
            if (result.state === 'granted') {
                setIsGeoLocationPermissionGranted(true);
                setGeoLocationPermissionStatus('granted');
            } else if (result.state === 'prompt') {
                requestGeoLocationPermission();
                setGeoLocationPermissionStatus('prompt');
            } else {
                setIsGeoLocationPermissionGranted(false);
                setGeoLocationPermissionStatus('denied');
            }

            result.onchange = () => {
                if (result.state === 'granted') {
                    setIsGeoLocationPermissionGranted(true);
                } else {
                    setIsGeoLocationPermissionGranted(false);
                }
            };
        })
        .catch((error) => {
            console.error('Error checking geolocation permissions:', error);
            setGeoLocationPermissionStatus('error');
        });
    };

    // const requestMicrophonePermission = async () => {
    //     try {
    //         const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    //         stream.getTracks().forEach(track => track.stop()); // Close the stream after getting permission
    //         setIsMicPermissionGranted(true);
    //     } catch (error) {
    //         console.error('Error accessing microphone:', error);
    //         setIsMicPermissionGranted(false);
    //     }
    // };

    // const checkMicrophonePermission = async () => {
    //     try {
    //         const result = await navigator.permissions.query({ name: 'microphone' });
    //         if (result.state === 'granted') {
    //             setIsMicPermissionGranted(true);
    //         } else if (result.state === 'prompt') {
    //             requestMicrophonePermission();
    //         } else {
    //             setIsMicPermissionGranted(false);
    //         }

    //         result.onchange = () => {
    //             if (result.state === 'granted') {
    //                 setIsMicPermissionGranted(true);
    //             } else {
    //                 setIsMicPermissionGranted(false);
    //             }
    //         };
    //     } catch (error) {
    //         console.error('Error checking microphone permissions:', error);
    //     }
    // };

    return (
        <PWAContext.Provider value={{ isMicPermissionGranted, requestMicrophonePermission }}>
            {children}
            <div>
                Microphone Permission Status: {micPermissionStatus}
            </div>
            <div>
                Geolocation Permission Status: {geoLocationPermissionStatus}
            </div>
        </PWAContext.Provider>
    );
};

export const usePWA = () => useContext(PWAContext);