import React, { useState, useRef, useEffect } from 'react';
import { Button } from '@nextui-org/react';

import { usePWA } from './context/pwa_context';

import { FaPlay } from "react-icons/fa";
import { FaStop } from "react-icons/fa";
import { FaTrash } from "react-icons/fa";

export function VoiceRecordPress() {
    const { isMicPermissionGranted, requestMicrophonePermission } = usePWA();

    const [audioUrl, setAudioUrl] = useState('');
    const [isRecording, setIsRecording] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);
    const mediaRecorderRef = useRef(null);
    const audioChunksRef = useRef([]);
    const startTimeRef = useRef(null);
    const audioContextRef = useRef(new (window.AudioContext || window.webkitAudioContext)());
    const bufferRef = useRef(null);
    const sourceRef = useRef(null);

    const startRecording = async () => {
        if (!isMicPermissionGranted) {
            console.error('Microphone permission not granted');
            await requestMicrophonePermission();
            if (!isMicPermissionGranted) return;
        }

        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            mediaRecorderRef.current = new MediaRecorder(stream);
            mediaRecorderRef.current.ondataavailable = (event) => {
                if (event.data.size > 0) {
                    audioChunksRef.current.push(event.data);
                }
            };
            mediaRecorderRef.current.onstop = () => {
                const duration = Date.now() - startTimeRef.current;
                if (duration >= 1000) { // Check if the recording duration is at least 1 second
                    const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/wav' });
                    const url = URL.createObjectURL(audioBlob);
                    setAudioUrl(url);
                    audioChunksRef.current = [];
                } else {
                    console.log('Recording is too short');
                }
            };
            mediaRecorderRef.current.start();
            setIsRecording(true);
            startTimeRef.current = Date.now();
        } catch (error) {
            console.error('Error accessing microphone:', error);
        }
    };

    const stopRecording = () => {
        if (mediaRecorderRef.current) {
            mediaRecorderRef.current.stop();
        }
        setIsRecording(false);
    };

    useEffect(() => {
        if (audioUrl) {
            fetch(audioUrl)
                .then(response => response.arrayBuffer())
                .then(buffer => audioContextRef.current.decodeAudioData(buffer))
                .then(decodedData => {
                    bufferRef.current = decodedData;
                })
                .catch(error => console.error('Error with audio playback:', error));
        }
    }, [audioUrl]);

    const playAudio = () => {
        if (sourceRef.current) {
            sourceRef.current.stop();
            sourceRef.current.disconnect();
            sourceRef.current = null;
            setIsPlaying(false);
            return;
        }
    
        if (bufferRef.current) {
            const source = audioContextRef.current.createBufferSource();
            source.buffer = bufferRef.current;
            source.connect(audioContextRef.current.destination);
            source.onended = () => {
                setIsPlaying(false);
                source.disconnect();
                sourceRef.current = null;
            };
            sourceRef.current = source;
            source.start(0);
            setIsPlaying(true);
        }
    };    

    const deleteRecording = () => {
        setAudioUrl('');
        bufferRef.current = null;
        if (sourceRef.current) {
            sourceRef.current.stop();
            sourceRef.current.disconnect();
            sourceRef.current = null;
        }
        setIsPlaying(false);
    };

    return (
        <div>
            <Button
                onPressStart={startRecording}
                onPressEnd={stopRecording}
                isDisabled={isRecording}
                variant="solid"
                color="primary"
                size='sm'
            >
                {isRecording ? 'Recording...' : 'Hold to Record'}
            </Button>

            <div className='mt-2 space-x-2'>
                {audioUrl && (
                    <>
                        {isPlaying ? (
                            <Button size='sm' onClick={playAudio} isIconOnly color="danger" aria-label="stop">
                                <FaStop />
                            </Button> 
                        ) : (
                            <Button size='sm' onClick={playAudio} isIconOnly color="primary" aria-label="play">
                                <FaPlay />
                            </Button>
                        )}

                        <Button size='sm' onClick={deleteRecording} isIconOnly color="danger" aria-label="play">
                            <FaTrash />
                        </Button>
                    </>
                )}
            </div>
        </div>
    );
};