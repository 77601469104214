import React, { useState, useEffect, useRef } from "react";

import {Link} from "@nextui-org/react";
import {Tabs, Tab, Card, CardHeader, CardBody, CardFooter, Image, Button, Navbar, NavbarBrand, NavbarContent, NavbarItem, NavbarMenuToggle, NavbarMenu, NavbarMenuItem, Tooltip} from "@nextui-org/react";

// import { VoiceRecordPress } from "../components/voice_record_press";

export function Index() {
    // useEffect(() => {
    // }, []);

    return (
        <>
            <div className="p-6 mx-auto max-w-5xl">
                <div>
                    <p className="text-5xl">This is the landing page</p>
                    <p className="text-default-500">landing page stuff here blah blah blah</p>
                    
                    <div className="mt-4">
                        <Button as={Link} href="/getapp">Get App</Button>
                    </div>

                    <div className="mt-4">
                        <p>test to show that these routes are "protected" by pwa prompt if user is not accessing via pwa</p>
                        <div className="space-x-4">
                            <Link href="/app">app home</Link>
                            <Link href="/app/test">app test</Link>
                        </div>
                    </div>
                </div>

                {/* <div className="mt-4">
                    <VoiceRecordPress />
                </div> */}
            </div>
        </>
    )
}

