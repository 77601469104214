import React, { useState, useEffect, useRef } from "react";

import {Link} from "@nextui-org/react";
import {Tabs, Tab, Card, CardHeader, CardBody, CardFooter, Image, Button, Navbar, NavbarBrand, NavbarContent, NavbarItem, NavbarMenuToggle, NavbarMenu, NavbarMenuItem, Tooltip} from "@nextui-org/react";

export function AppIndex() {
    // useEffect(() => {
    // }, []);

    return (
        <>
            <div className="p-6 mx-auto max-w-5xl">
                <div>
                    <p className="text-5xl">This is the app homepage</p>
                    <p className="text-default-500">app home page stuff here blah blah blah</p>
                </div>

                <Link href="/app/test">app test</Link>
            </div>
        </>
    )
}

