import React, { useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import {NextUIProvider} from "@nextui-org/react";

import { AlertProvider } from "./components/context/alert_context";
import { PWAProvider } from "./components/context/pwa_context";

import { Index } from "./pages/index";
import { GetApp } from "./pages/get_app";
import { AppIndex } from "./pages/app/index";
import { AppTest } from "./pages/app/test";

function App() {
	const navigate = useNavigate();

	useEffect(() => {
        const root = document.documentElement;
        // Always apply these classes
        root.classList.add('dark', 'text-foreground', 'bg-background');

        // Cleanup function removes 'dark' but leaves others
        return () => {
            root.classList.remove('dark', 'text-foreground', 'bg-background');
        };
    }, []);

	return (
		<NextUIProvider navigate={navigate}>
			<AlertProvider>
				<PWAProvider>
					<Routes>
						<Route path='/' element={ <Index /> } />
						<Route path='/getapp' element={ <GetApp /> } />
						<Route path='/app' element={ <AppIndex /> } />
						<Route path='/app/test' element={ <AppTest /> } />

						<Route path='*' element={<Index />} />
					</Routes>
				</PWAProvider>
			</AlertProvider>
		</NextUIProvider>
	);
}

export default App;

// need to do:
// service worker - test push notifications - make a route in flask that u can post 
// to w a secret key that sends a notification (message in body)

// record voice button w playback